/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { Sha256 } from '@aws-crypto/sha256-browser';
import { Buffer } from 'buffer';

export enum HostedIdentityProvider {
  Cognito = 'COGNITO',
  Google = 'Google',
  Facebook = 'Facebook',
  Amazon = 'LoginWithAmazon',
  Apple = 'SignInWithApple',
  OktaReef = 'OktaReefInternal',
}

import { getPKCE, getState, setPKCE, setState } from './oauthStorage';

interface OAuth2Options {
  scopes?: string[];
  clientId: string;
  domain: string;
  redirectUri: string;
}

interface AuthToken {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

const urlOpener = (url: string, _?: string) => window.open(url, '_self');
const BUFFER_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const STATE_CHARS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

/**
 * Client inspired by amazon's amplify lib. With modifications.
 * This is the version of the oauth client we depended on.
 * https://github.com/aws-amplify/amplify-js/blob/%40aws-amplify/auth%405.6.12/packages/auth/src/OAuth/OAuth.ts
 */
export class OAuth {
  private clientId: string;
  private scopes: string[];
  private domain: string;
  private redirectUri: string;

  constructor({ clientId, scopes = [], domain, redirectUri }: OAuth2Options) {
    this.clientId = clientId;
    this.scopes = scopes;
    this.domain = domain;
    this.redirectUri = redirectUri;
  }

  public async oAuthSignIn(provider: HostedIdentityProvider, email?: string) {
    const params = new URLSearchParams();
    params.set('response_type', 'code');
    params.set('redirect_uri', this.redirectUri);
    params.set('client_id', this.clientId);
    params.set('identity_provider', provider);
    params.set('scope', this.scopes.join(' '));
    if (email && provider === HostedIdentityProvider.OktaReef) {
      params.set('login_hint', email);
    }

    const state = this._generateState(32);
    setState(state);
    params.set('state', state);

    const pkce_key = this._generateRandom(128);
    setPKCE(pkce_key);
    params.set('code_challenge', await this._generateChallenge(pkce_key));
    params.set('code_challenge_method', 'S256');

    const URL = `https://${this.domain}/oauth2/authorize?${params}`;
    console.debug(`Redirecting to ${URL}`);

    urlOpener(URL);
  }

  private async _handleCodeFlow(currentUrl: URL): Promise<AuthToken | null> {
    /* Convert URL into an object with parameters as keys
    { redirect_uri: 'http://localhost:3000/', response_type: 'code', ...} */
    const url = new URL(currentUrl);
    const code = url.searchParams.get('code');

    if (!code) {
      return null;
    }

    const oAuthTokenEndpoint = `https://${this.domain}/oauth2/token`;

    const oAuthTokenBody = new URLSearchParams();
    oAuthTokenBody.set('grant_type', 'authorization_code');
    oAuthTokenBody.set('code', code);
    oAuthTokenBody.set('client_id', this.clientId);
    oAuthTokenBody.set('redirect_uri', this.redirectUri);

    const codeVerifier = getPKCE();
    if (codeVerifier != null) {
      oAuthTokenBody.set('code_verifier', codeVerifier);
    }

    console.debug(`Calling token endpoint: ${oAuthTokenEndpoint} with`, oAuthTokenBody);

    const { access_token, refresh_token, id_token, error } = await (
      await fetch(oAuthTokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: oAuthTokenBody,
      })
    ).json();

    if (error) {
      throw new Error(error);
    }

    return {
      accessToken: access_token,
      refreshToken: refresh_token,
      idToken: id_token,
    };
  }

  public async handleAuthResponse(currentUrl: URL): Promise<AuthToken | null> {
    const url = new URL(currentUrl);
    const error = url.searchParams.get('error');
    const errorDescription = url.searchParams.get('error_description');

    if (error) {
      throw new Error(errorDescription ?? 'failed to handle oauth response');
    }

    this._validateState(url.searchParams);

    console.debug(`Starting 'code' flow with ${currentUrl}`);
    return this._handleCodeFlow(currentUrl);
  }

  private _validateState(urlParams: URLSearchParams) {
    const savedState = getState();
    const returnedState = urlParams.get('state');

    // This is because savedState only exists if the flow was initiated by Amplify
    if (savedState && savedState !== returnedState) {
      throw new Error('Invalid state in OAuth flow');
    }
  }

  public async signOut() {
    const params = new URLSearchParams();
    params.set('client_id', this.clientId);
    params.set('logout_uri', this.redirectUri);

    const url = `https://${this.domain}/logout?${params}`;
    console.debug(`Signing out from ${url}`);

    return urlOpener(url);
  }

  private _generateState(length: number): string {
    let result = '';
    let i = length;
    for (; i > 0; --i) result += STATE_CHARS[Math.round(Math.random() * (STATE_CHARS.length - 1))];
    return result;
  }

  // protected so we can unit test
  protected async _generateChallenge(code: string): Promise<string> {
    const awsCryptoHash = new Sha256();
    awsCryptoHash.update(code);

    const resultFromAWSCrypto = await awsCryptoHash.digest();
    const b64 = Buffer.from(resultFromAWSCrypto).toString('base64');
    const base64URLFromAWSCrypto = this._base64URL(b64);

    return base64URLFromAWSCrypto;
  }

  private _base64URL(string: string): string {
    return string.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  private _generateRandom(size: number): string {
    const buffer = new Uint8Array(size);
    window.crypto.getRandomValues(buffer);
    return this._bufferToString(buffer);
  }

  private _bufferToString(buffer: Uint8Array): string {
    const state = [];
    for (let i = 0; i < buffer.byteLength; i += 1) {
      const index = buffer[i] % BUFFER_CHARS.length;
      state.push(BUFFER_CHARS[index]);
    }
    return state.join('');
  }
}
